<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pb-3">
        <v-card-title>Initialize campaigns for use with Ad Customizer</v-card-title>
        <v-card-text>

          <v-row>
            <v-col>
              <p>This process will convert the labelled Expanded Text Ads of the selected campaigns for use with the Ad Customizer. It will:</p>
              <ul>
                <li>create Ad Customizer data feeds, if needed</li>
                <li>do the following for every Expanded Text Ad labelled as either feed A ("labela") or feed B ("labelb") in the selected campaigns:
                  <ul>
                    <li>create a feed item which can be managed using the Ad Customizer tool</li>
                    <li>clone the labelled ad, and the new ad will use data feed placeholders with the original ad's properties as fallback values</li>
                    <li>if the "Pause existing ads" checkbox is set: pause the original ad</li>
                  </ul>
                </li>
              </ul>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <p>Firstly, please validate the account to ensure that data feeds can be created. Among other checks, the account:</p>
              <ol class="mb-1">
                <li>must have an active domain mapping to a valid CMS host</li>
                <li>must either have no existing Ad Customizer data feeds, or the existing feed names must exactly match the expected pattern, e.g. en_SD_DE_FeedA</li>
                <li>must have 2 labelled Expanded Text Ads, 1 each for feed A and feed B, in at least 1 ad group</li>
              </ol>
              <p>The validation process may take up to 20 seconds.</p>
            </v-col>
          </v-row>

          <v-form ref="validationForm">
            <v-row>
              <v-col md="4">
                <GoogleAccountAutoComplete 
                  v-model="selectedAccountID"
                  @changed="accountChanged" 
                  @refreshing="accountsRefreshing"
                ></GoogleAccountAutoComplete>
              </v-col>

              <v-col md="2">
                <v-btn class="mt-3 ml-2" color="primary" :loading="validating" @click="validate" :disabled="!selectedAccountID">Validate</v-btn>
              </v-col>

              <v-col md="6">
                <p class="mt-4" :class="validationClass">{{ validationResult }}</p>
              </v-col>

            </v-row>
          </v-form>

          <v-row>
            <v-col>
              <p>If validation is successful, campaigns can be initialized! Please select all campaigns for which you want feed items to be created and ads to be cloned.</p>
              <p>Depending on the number of labelled ad groups per campaign, the feed creation process may take up to 1 minute per campaign chosen. The progress of the process will be shown in the table below.</p>
            </v-col>
          </v-row>

          <v-form ref="creationForm">
            <v-row>
              <v-col md="6">
                <GoogleCampaignsMultiAutoComplete 
                  v-model="selectedCampaignIDs"
                  :accountID="selectedAccountID"
                  :disabled="!validated"
                  getParams="&amp;ad_customizer_initialized_at=~0001-01-01~"
                  @changed="campaignsChanged" 
                ></GoogleCampaignsMultiAutoComplete>
              </v-col>

              <v-col md="3">
                <v-checkbox v-model="pauseExistingAds" label="Pause existing ads" :disabled="!validated"></v-checkbox>
              </v-col>

              <v-col md="3">
                <v-btn class="mt-3 ml-2" color="primary" :loading="creating" @click="create" :disabled="selectedCampaignIDs.length == 0">Create</v-btn>
              </v-col>

            </v-row>
          </v-form>

        </v-card-text>
      </v-card>

      <v-card class="mt-6">
        <v-card-text>
          <SystemJobsTable :headers="jobHeaders" :jobType="jobType" :updateKey="progressTableKey"></SystemJobsTable>
        </v-card-text>
      </v-card>

    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'AdCustomizerInitializeCampaigns',

  data () {
    return {
      selectedAccountID: 0,
      lsSelectedAccount: 'adCustomizer_selectedAccount',
      validating: false,
      validated: false,
      validationResult: '',
      selectedCampaignIDs: [],
      pauseExistingAds: false,
      creating: false,
      created: false,
      progressTableKey: 'init',
      jobType: 'Adwords - Ad Customizer Initialize Campaigns',
      jobHeaders: [
        { text: 'User', value: 'user_name' },
        { text: 'Account', value: 'text1' },
        { text: '# Campaigns', value: 'text2' },
        { text: 'Pause existing ads', value: 'text3' },
        { text: 'Status', value: 'status' },
        { text: 'Entry at', value: 'entry_at_time' },
        { text: 'Completed at', value: 'completed_at_time' },
        { text: 'External message', value: 'external_message' },
      ],
    }
  },

  computed: {
    creationEndpoint () {
      return '/a/google/accounts/' + this.selectedAccountID + '/initialize-for-ad-customizer'
    },

    validateAccountEndpoint () {
      return '/a/google/accounts/' + this.selectedAccountID + '/validate-for-ad-customizer-initialization'
    },

    validationClass () {
      return this.validated ? 'success--text' : 'error--text'
    }
  },

  components: {
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    GoogleCampaignsMultiAutoComplete: () => import('@/components/autocompletes/GoogleCampaignsMultiAutoComplete.vue'),
    SystemJobsTable: () => import('@/components/tables/SystemJobsTable.vue'),
  },

  methods: {
    accountChanged () {
      localStorage.setItem(this.lsSelectedAccount, JSON.stringify(this.selectedAccountID))
      this.validated = false,
      this.validationResult = '',
      this.selectedCampaignIDs = []
      this.created = false
    },

    accountsRefreshing () {
      this.validated = false,
      this.validationResult = '',
      this.selectedCampaignIDs = []
      this.created = false
    },

    campaignsChanged () {
      this.created = false
    },

    create () {
      if (!this.$refs.creationForm.validate()) {
        return
      }
      this.creating = true

      // gather params and send to API
      var creationObj = {
        'campaign_ids': this.selectedCampaignIDs,
        'pause_existing_ads': this.pauseExistingAds,
      }
      var body = JSON.stringify(creationObj)
      //console.log(body)

      this.$http.post(this.creationEndpoint, body).then(resp => {
        this.progressTableKey = this.$dateTime.now().toString()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.creationEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.creating = false)
    },

    validate () {
      if (!this.$refs.validationForm.validate()) {
        return
      }
      this.validating = true
      this.validated = false
      this.validationResult = ''

      this.$http.get(this.validateAccountEndpoint).then(resp => {
        if (resp.data.status === 'succeeded') {
          this.validated = true
          this.validationResult = 'Success: ' + resp.data.data
          //this.getCampaigns()
        } else {
          this.validated = false
          this.validationResult = 'Failed: ' + resp.data.data
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.validateAccountEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.validating = false)
    },
  },

  created: function () {
    // if present, use local selected account
    if (localStorage.getItem(this.lsSelectedAccount)) {
      this.selectedAccountID = JSON.parse(localStorage.getItem(this.lsSelectedAccount))
      this.accountChanged()
    }
  },
}
</script>
